import logo from "./logoGreener.png";
import icone from "./logo_greneer.svg"
import "./App.css";
//import ReactThreeFbxViewer from 'react-three-fbx-viewer';
//import {ReactComponent as logo} from "./logo_greener.svg";
import bigLogo from "./art.svg";
import greener from "./LOGO GREENER AGENCY transparent - Copie.svg";
import React, { useEffect } from 'react'
//let fbxUrl = require('./22-03-23_LF-Boite3D.fbx');

function App() {
  useEffect(() => {
    document.title = "Greener"
  }, [])
  return (
    <app>
      <div className="body">
        <div className={"circles"}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <main>
          <header>
            <img id="logo" src={greener} alt={"logo"} />
          </header>
          <div className="container">
            <section>
              <div className="title">
                <div className="subtitle">
                  NOUS SOMMES GREENER
                {/* NOUS SOMMES <img id="logo" src={greener} alt={"logo"} /> */}
                </div>
                <h1 className="headline">
                  L'agence qui porte les valeurs du{" "}
                  <span style={{ position: "relative" }}>
                    futur
                    <div
                      style={{
                        position: "absolute",
                        borderBottom: "solid 12px #b3c751",
                        width: "2.3em",
                        left: "0",
                        transform: "translate(-25px,-20px)",
                        zIndex: "-1",
                      }}
                    ></div>
                  </span>
                </h1>
              </div>
              <img id="bigLogo" src={bigLogo} alt={"bigLogo"} />
            </section>
          </div>
          <footer>
            <div id="scrollWrapper">
              <div id="scroll">SCROLL</div>
              <div id="bar"></div>
            </div>
          </footer>
        </main>
      </div>
    </app>
  );
}

export default App;
